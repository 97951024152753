<div class="report-form">
  <mat-drawer-container class="report-drawer-container" [hasBackdrop]="false">
    <mat-drawer #drawer [opened]="false" [mode]="'over'" [position]="'end'">
      <div class="drawer-container">
        <mat-list>
          <div mat-subheader>{{ "FORMS" | translate }}</div>
          @for (item of formTemplates; track $index) {
          <mat-list-item
            [class.active]="template === item"
            (click)="template = item; drawer.toggle()"
            matRipple
          >
            <mat-icon
              matListItemIcon
              fontIcon="mdi-file"
              fontSet="mdi"
            ></mat-icon>
            <div mat-line>{{ item.label }}</div>
          </mat-list-item>
          }
        </mat-list>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <button class="menu-btn" mat-icon-button (click)="drawer.toggle()">
        <mat-icon
          [fontIcon]="drawer.opened ? 'mdi-arrow-right' : 'mdi-menu'"
          fontSet="mdi"
        ></mat-icon>
      </button>
      <!-- <ft-form [(template)]="template"></ft-form> -->

      @if (!template) {
      <div class="no-content">
        <button
          class="chose-button"
          mat-raised-button
          color="primary"
          (click)="drawer.toggle()"
        >
          <mat-icon fontSet="mdi" fontIcon="mdi-menu"></mat-icon>
          {{ "CHOSE_FORM" | translate }}
        </button>
      </div>
      } @if (template) {
      <div
        class="ft-action fx-layout-row fx-content-end fx-items-center fx-gap-8"
      >
        <button
          class="save-button"
          mat-raised-button
          color="primary"
          (click)="goToPrevious()"
        >
          <mat-icon fontSet="mdi" fontIcon="mdi-arrow-left"></mat-icon>
          {{ "PREVIOUS" | translate }}
        </button>
        <span class="fx-grow-1"></span>
        <button
          class="save-button"
          mat-raised-button
          color="primary"
          (click)="saveForm()"
        >
          {{ "SAVE" | translate }}
        </button>
        <button
          class="generate-button"
          mat-raised-button
          color="warn"
          (click)="generateReport()"
        >
          {{ "GENERATE_REPORT" | translate }}
        </button>
      </div>
      }
    </mat-drawer-content>
  </mat-drawer-container>
</div>
