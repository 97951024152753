<div id="ft-player" class="fx-layout-column fx-content-space-between">
	<mat-toolbar class="dialog-toolbar">
		<mat-icon fontSet="mdi" fontIcon="mdi-disc-player"></mat-icon>
		<h3 style="padding-left: 6px">{{ 'AUDIO_PLAYER' | translate }}</h3>
		<span class="fx-grow-1"></span>
		<button mat-icon-button mat-dialog-close tabindex="-1">
			<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
		</button>
	</mat-toolbar>

	<div class="fx-grow-1" mat-dialog-content style="overflow: visible">
		@if (audioFile) {
			<audio controls>
				<source [src]="audioFile.src" [type]="audioFile.type" />
				Your browser does not support the audio element.
			</audio>
		}
	</div>
</div>
