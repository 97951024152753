import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

import { get, isNil, noop } from 'lodash';
import { MatDialogActions, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import moment from 'moment';
import { deleteItemFromArray, FileService } from '../../shared';
import { formatTimer } from '../../utils';
import { FtAudioPlayerComponent } from '../../shared/ft-audio-player/ft-audio-player.component';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { MatToolbar } from '@angular/material/toolbar';

declare let window: any;

export interface AudioFile {
	src: string;
	type: string;
	blob: Blob;
	duration: number;
}

@Component({
	standalone: true,
	selector: 'ft-audio-recorder',
	templateUrl: './audio-recorder.component.html',
	styleUrls: ['./audio-recorder.component.scss'],
	imports: [
		FtAudioPlayerComponent,
		MatIconButton,
		MatIcon,
		MatProgressBar,
		MatDialogActions,
		MatButton,
		TranslateModule,
		MatDialogClose,
		MatToolbar,
	],
})
export class AudioRecorderComponent
	implements OnInit, OnDestroy, AfterViewInit
{
	record = null;
	stream = null;
	audioChunks: Blob[] = [];

	isPlay = false;
	isStop = true;
	hasNoFile = true;

	showSave = false;
	err = true;

	audio = null;
	timer: number = 0;
	interval: any;

	player: any;

	// audioSources: any[] = [];
	duration: number;
	subs: Subscription[] = [];

	private dataSubject = new BehaviorSubject<Blob>(null);
	canStop: boolean = false;
	private released: boolean;
	private blobFile: Blob;

	private finalFileName: string = 'NONE';

	audioFiles: AudioFile[] = [];
	saving: boolean;
	private ft_recorder: HTMLElement;

	constructor(
		private service: FileService,
		private dialogRef: MatDialogRef<AudioRecorderComponent>
	) {}

	startTimer() {
		this.interval = setInterval(() => {
			this.timer += 1;
		}, 10);
	}

	resetTimer() {
		this.pauseTimer();
		this.timer = 0;
	}

	pauseTimer() {
		clearInterval(this.interval);
	}

	ngOnInit() {
		this.subs.push(
			this.dataSubject.asObservable().subscribe(data => {
				if (data) {
					this.audioChunks = [];
					this.audioChunks.push(data);
					this.updatePlayer(this.audioChunks);
				}
			})
		);
	}

	handleStream(stream) {
		this.stream = stream;

		this.record = new window.MediaRecorder(this.stream);
		this.record.ondataavailable = e => {
			this.dataSubject.next(e.data);
		};
	}

	updatePlayer(data) {
		this.hasNoFile = false;

		if (data.length !== 0) {
			this.duration = this.timer / 100;

			this.blobFile = new Blob(data, { type: 'audio/x-mpeg-3' });
		}
	}

	startRecording() {
		if (this.record.state === 'inactive') this.record.start();

		// this.audioSources = [];

		this.startTimer();

		this.isPlay = true;
		this.isStop = false;
		this.showSave = false;
		this.canStop = false;
	}

	pauseRecording() {
		// this.audioSources = [];
		this.finalFileName = moment().format('YYYYMMDDHHMMSS');

		// this.uuid = `${this.prevFileName}_${this.chunkFileName}_${this.finalFileName}`;

		if (this.record.state !== 'inactive') this.record.stop();

		this.pauseTimer();
		this.isPlay = this.isStop = false;
		this.canStop = true;

		this.duration = Math.floor(this.timer / 100) % 60;

		if (this.record.state !== 'inactive') this.record.stop();

		this.isPlay = false;
		this.isStop = true;

		setTimeout(() => this.audioFiles.push(this.buildSource()));
	}

	uploadAudioFiles(files: File[], uuid: string): Observable<any> {
		// start the upload and save the progress map
		const progress = this.service.uploadAudioFiles(files, uuid);

		// convert the progress map into an array
		const allProgressObservables = [];
		for (const key in progress)
			allProgressObservables.push(progress[key]['progress']);

		// When all progress-observables are completed...
		return forkJoin(allProgressObservables);
	}

	error() {
		this.err = true;
	}

	saveFile() {
		let uuids = 'NONE';
		let i = 0;
		const files = this.audioFiles.map(audio => {
			const uuid = moment().format('YYYYMMDDHHMMSS') + i++;
			const file = new File([audio.blob], `${uuid}.mp3`, {
				type: 'audio/x-mpeg-3',
			});
			uuids += `_${uuid}`;
			return file;
		});

		this.uploadAudioFiles(files, uuids).subscribe(value => {
			if (value[value.length - 1] === 100) {
				setTimeout(() => {
					this.service.concatAudioChunks(uuids).subscribe(value1 => {
						if (value1.data) {
							this.dialogRef.close({ file: value1.text });
						}
					});
				}, 500);
			}
		});
	}

	ngAfterViewInit(): void {
		const getUserMedia = get(
			window,
			'navigator.mediaDevices.getUserMedia',
			null
		);
		const mediaRecorder = get(window, 'MediaRecorder', null);

		if (!isNil(getUserMedia) && !isNil(mediaRecorder)) {
			window.navigator['mediaDevices']['getUserMedia']({ audio: true }).then(
					(stm: any) => this.handleStream(stm),
					() => this.error()
				);
		} else console.log('MediaRecord not supported');

		this.released = false;

		this.ft_recorder = document.getElementById('ft-recorder');
		window.addEventListener(
			'keydown',
			e => {
				if (e.altKey && e.ctrlKey) return;
				this.handleRecordActions(e.key);
			},
			true
		);
	}

	ngOnDestroy() {
		isNil(this.stream)
			? noop()
			: this.stream['getAudioTracks']().forEach(function (track) {
					track.stop();
				});

		this.subs.forEach(sub => sub.unsubscribe());
		this.ft_recorder.removeEventListener('keydown', e =>
			console.log('remove listener')
		);
	}

	public getTimerString(timer: number) {
		return formatTimer(timer);
	}

	private buildSource(): AudioFile {
		const blob = new Blob([this.blobFile], { type: 'audio/x-mpeg-3' });
		const _audioFile = {
			src: URL.createObjectURL(blob),
			type: 'audio/mp3',
			blob: blob,
		};

		const duration = this.duration;

		this.resetTimer();
		return { ..._audioFile, duration };
	}

	deleteRecord(audio: AudioFile) {
		deleteItemFromArray(this.audioFiles, audio);
	}

	private handleRecordActions(key: string) {
		if (key.toLowerCase() === 'i') {
			this.startRecording();
		}
		if (key.toLowerCase() === 'r') {
			this.released = true;
			this.pauseRecording();
		}

		if (key.toLowerCase() === 's' && this.released) {
			this.player.togglePlay();
		}

		if (key.toLowerCase() === 'f' && this.released) {
			this.player.forward();
		}

		if ((key === 'b' || key === 'B') && this.released) {
			this.player.rewind();
		}
	}
}
