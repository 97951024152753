import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ReportingService } from "../reporting.service";
import { ReportingTask, ReportingTaskDTO } from "../../model";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "ft-reporting-form",
  templateUrl: "./reporting-form.component.html",
  styleUrls: ["./reporting-form.component.scss"],
})
export class ReportingFormComponent implements OnChanges {
  template: any;
  formTemplates: any;
  patientFormTemplates: any;
  private examFormTemplate: any;

  @Input() reportingTask: ReportingTask | ReportingTaskDTO;

  @Output() generateReportEvent = new EventEmitter();
  @Output() previousEvent = new EventEmitter();

  constructor(
    private service: ReportingService,
    private _snackBar: MatSnackBar
  ) {
    this.service
      .getAllFormTemplates()
      .subscribe((data) => (this.formTemplates = data));
  }

  ngOnChanges(changes: SimpleChanges) {
    const { reportingTask } = changes;

    if (reportingTask.currentValue) {
      this.service
        .getExamFormTemplates(
          reportingTask.currentValue.patientID,
          reportingTask.currentValue.accessionNumber
        )
        .subscribe(
          (data) => {
            this.patientFormTemplates = data;
            this.examFormTemplate = data[0];
            this.template = this.examFormTemplate?.template;
          },
          (error) => {
            this._snackBar.open(error.statusText, error.status, {
              duration: 3600,
              horizontalPosition: "center",
            });
          }
        );
    }
  }

  saveForm() {
    const exam_form_template = {
      id: this.examFormTemplate?.id,
      patient_id: this.reportingTask.patientID,
      exam_id: this.reportingTask.accessionNumber,
      template: this.template,
    };
    this.service
      .saveExamFormTemplate(exam_form_template)
      .subscribe(console.log);
  }

  goToPrevious() {
    this.previousEvent.emit(true);
  }

  generateReport() {
    this.saveForm();
    this.generateReportEvent.emit(this.template);
  }
}
