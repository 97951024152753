import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileService } from '../../shared';
import { Subscription } from 'rxjs';
import { ReportingService } from '../reporting.service';

@Component({
	selector: 'ft-audio-player',
	templateUrl: './audio-player.component.html',
	styleUrl: './audio-player.component.scss',
})
export class AudioPlayerComponent implements OnDestroy {
	audio: any;

	player: any;

	audioFile!: any;
	duration: number;
	subscription: Subscription;
	subscription2: Subscription;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private service: FileService,
		private reportingService: ReportingService
	) {
		this.subscription = this.service
			.getAudioFile(`${this.data.record}.mp3`)
			.subscribe(blob => {
				this.audioFile = {
					src: URL.createObjectURL(
						new Blob([blob], { type: 'audio/x-mpeg-3' })
					),
					type: 'audio/mp3',
				};
			});

		this.subscription2 = this.reportingService.keyboardEventSubject
			.asObservable()
			.subscribe(e => this.handleRecordActions(e));
	}

	private handleRecordActions(e: KeyboardEvent) {
		if (e.key.toLowerCase() === 's' && e.shiftKey && e.ctrlKey && e.altKey)
			this.player.togglePlay();
		if (e.key.toLowerCase() === 'f' && e.shiftKey && e.ctrlKey && e.altKey)
			this.player.forward();
		if (e.key.toLowerCase() === 'b' && e.shiftKey && e.ctrlKey && e.altKey)
			this.player.rewind();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
		this.subscription2.unsubscribe();
	}
}
