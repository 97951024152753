import { Component, ElementRef, input, viewChild } from '@angular/core';
import { AudioFile } from '../../reporting/audio-recorder/audio-recorder.component';

@Component({
  selector: 'ft-audio-player2',
  standalone: true,
  imports: [],
  templateUrl: './ft-audio-player.component.html',
  styleUrl: './ft-audio-player.component.scss'
})
export class FtAudioPlayerComponent {
  audio = viewChild<ElementRef>('audio');
  file = input.required<AudioFile>({alias: 'audioFile'});


  playAudio() {
    this.audio().nativeElement.play();
  }

  pauseAudio() {
    this.audio().nativeElement.pause();
  }
}
